<template>
  <div class="documents-secure-list-container">
    <router-view name="sidebar" @save="refresh" @hidden="navigateToSecureList" @toggleEdit="navigateToEditForm" />
    <document-secure-toolbar v-if="parent != parentTypes.EDITOR && !includesDeleted" />
    <div class="aula-documentsSecureList-container page-layout-padding">
      <h1 v-if="!includesDeleted" class="document-title">
        <b-btn
          variant="link"
          class="file-document"
          @click="$router.push({ name: 'documents' })"
          @keypress.enter="$router.push({ name: 'documents' })"
        >
          {{ 'DOCUMENTS_TYPES_PAGE_TITLE' | fromTextKey }}
        </b-btn>
        <span class="breadcumb-spacer">/</span>
        <strong> {{ 'DOCUMENTS_TYPES_SECURE_FILES_TITLE' | fromTextKey }}</strong>
      </h1>
      <div v-else class="deleted-secure-documents-text-container">
        <h1 class="header-text">{{ 'DELETED_SECURE_DOCUMENTS_HEADER' | fromTextKey }}</h1>
        <p class="description-text">{{ 'DELETED_SECURE_DOCUMENTS_DESCRIPTION' | fromTextKey }}</p>
      </div>
      <div>
        <b-row v-if="profile.role === portalRoles.EMPLOYEE" class="mb-3">
          <b-col sm="12" md="4" class="mb-1">
            <aula-search-recipients
              id="filter-group"
              :doc-types-api="[docTypes.GROUP]"
              :from-module="moduleTypes.SECURE_DOCUMENT"
              :show-search-icon="true"
              :multiple="true"
              :list-my-groups-on-focus="true"
              :split-groups-participants="false"
              :enable-relatives-for-students="false"
              :close-dropdown-after-select="false"
              :allow-create="false"
              :scope-employees-to-institution="false"
              :placeholder-textkey="'DOCUMENTS_SECURE_FILTER_CHILDREN'"
              @emitSelected="updateFilter"
            />
          </b-col>
          <b-col sm="12" md="4" class="mb-1">
            <aula-select
              v-model="selectedFilterPerson"
              filterable
              class="mb-1"
              :aria-label="'ARIA_LABEL_DOCUMENTS_SECURE_FILTER_BY_CHILD' | fromTextKey"
              @change="updateFilter()"
            >
              <aula-option v-for="(filter, i) in filterPerson" :key="i" :label="filter.text" :value="filter.value" />
            </aula-select>
          </b-col>
          <b-col v-if="profile.role !== portalRoles.GUARDIAN" sm="12" md="4" class="mb-1">
            <aula-select
              v-model="selectedFilterCategory"
              filterable
              :aria-label="'ARIA_LABEL_DOCUMENTS_SECURE_FILTER_BY_CATEGORY' | fromTextKey"
              @change="updateFilter()"
            >
              <aula-option v-for="(filter, i) in filterCategory" :key="i" :label="filter.text" :value="filter.value" />
            </aula-select>
          </b-col>
        </b-row>
        <div v-if="canShowMultipleFileActionButtons" class="filter multiple-file-actions">
          <b-btn
            v-if="hasPermission(permissionEnum.JOURNALING_TO_ESDH)"
            variant="primary"
            style="width: auto"
            @click="handleMultipleEsdhExportClicked()"
          >
            {{ $t('document.journalise_to_esdh_system') }}
          </b-btn>
          <b-btn
            v-if="hasPermission(permissionEnum.EXPORT_SECURE_FILES)"
            variant="primary"
            class=""
            style="width: auto"
            @click="showModalExportPDF()"
          >
            {{ 'DOCUMENTS_SHARED_OVERVIEW_MULTIPLE_EXPORT' | fromTextKey }}
          </b-btn>
          <b-btn variant="primary" style="width: auto" @click="addSharingGroups()">
            {{ 'DOCUMENTS_SHARED_OVERVIEW_MULTIPLE_SHARE' | fromTextKey }}
          </b-btn>
        </div>
      </div>
      <b-row v-if="!includesDeleted" class="secure-files-filter-container">
        <b-col cols="8">
          <div v-if="profile.role === portalRoles.GUARDIAN">
            {{ 'DOCUMENTS_SHARED_OVERVIEW_GUARDIAN_WARNING' | fromTextKey }}
          </div>
        </b-col>
        <b-col cols="4" class="dropdown-filter-container">
          <b-dropdown ref="sorter" class="dropdown-select sorter" right>
            <template slot="button-content">
              <span class="sort ml-n1" :class="sortDirection" />
              {{ sortTexKeyMap.get(sort) | fromTextKey }}
              <i class="icon icon-Aula_down-arrow" />
            </template>
            <b-dropdown-item
              :class="sort === sortTypes.TITLE ? sortDirection : ''"
              @click="setSortOrder(sortTypes.TITLE)"
            >
              {{ 'DOCUMENTS_SHARED_OVERVIEW_TABLE_TITLE' | fromTextKey }}
            </b-dropdown-item>
            <b-dropdown-item
              :class="sort === sortTypes.UPDATED_AT ? sortDirection : ''"
              @click="setSortOrder(sortTypes.UPDATED_AT)"
            >
              {{ 'DOCUMENTS_SHARED_OVERVIEW_TABLE_EDITED' | fromTextKey }}
            </b-dropdown-item>
          </b-dropdown>
        </b-col>
      </b-row>
      <ObservingContainer :options="observerOptions">
        <div class="table">
          <div :data-parent-type="parent" class="table-row header">
            <div v-if="canShowMultiSelectAction" class="table-cell multi-select-header" />
            <div class="table-cell title-header">
              {{ 'DOCUMENTS_SHARED_OVERVIEW_TABLE_TITLE' | fromTextKey }}
            </div>
            <div class="table-cell description-header">
              {{ 'DOCUMENTS_SHARED_OVERVIEW_TABLE_DESCRIPTION' | fromTextKey }}
            </div>
            <div class="table-cell regarding-children-header">
              {{ 'DOCUMENTS_SHARED_OVERVIEW_TABLE_ASSOCIATION' | fromTextKey }}
            </div>
            <div class="table-cell">{{ documentsTableTimestampHeader }}</div>
            <div v-if="profile.role === portalRoles.EMPLOYEE" class="table-cell" />
          </div>
          <div v-for="(item, i) in documents" :key="'d' + i" class="table-row body" @click="rowClickAction(item)">
            <div v-if="canShowMultiSelectAction" class="table-cell not-collapsed check">
              <span class="sr-only">{{ 'ARIA_LABEL_OPEN_EDIT_DOCUMENT' | fromTextKey }}</span>
              <b-form-checkbox v-model="status" :value="item.id" @click.native.stop="checkClick">
                <span class="sr-only">{{ 'ARIA_LABEL_CHOOSE_DOCUMENT' | fromTextKey }}</span>
              </b-form-checkbox>
            </div>
            <div class="table-cell title not-collapsed">
              <div v-if="item.documentType == documentTypes.EXTERNAL" class="filetype">
                {{ 'DOCUMENTS_SHARED_OVERVIEW_TABLE_IMPORTED_FILE' | fromTextKey }}
              </div>
              <badge-notification
                v-if="notifications.filter(not => not.documentId == item.id).length > 0 && !includesDeleted"
                :show-exclamation-icon-instead-of-amount="true"
                class="documents badge-notification"
              />
              <strong>{{ item.title }}</strong> ({{ item.category }})
              <div v-if="item.isSharedWithGuardian && profile.role != portalRoles.GUARDIAN" class="marking-text">
                {{ 'DOCUMENTS_SHARED_WITH_GUARDIAN_MARK' | fromTextKey }}
              </div>
            </div>
            <div class="table-cell not-collapsed">
              {{ getDocumentDescription(item) }}
            </div>
            <div v-if="parent != parentTypes.EDITOR" class="table-cell not-collapsed">
              <span v-for="(shared, index) in item.associatedInstitutionProfiles" :key="'sp' + index"
                ><span v-if="index > 0">, </span>{{ shared | displayProfileNameWithMetadata }}</span
              >
            </div>
            <div class="table-cell not-collapsed">
              <nobr> {{ getDocumentsTableDate(item) }} </nobr>
              <nobr> {{ getDocumentsTableTime(item) }}</nobr>
            </div>
            <div v-if="!isDocumentFormShown" class="table-cell collapsed-only">
              <div class="pull-left">
                <div class="item">
                  <badge-notification
                    v-if="notifications.filter(not => not.documentId == item.id).length > 0"
                    :show-exclamation-icon-instead-of-amount="true"
                    class="documents badge-notification"
                  />
                  <strong>{{ item.title }}</strong>
                  ({{ item.category }})
                  <div v-if="item.isSharedWithGuardian && profile.role != portalRoles.GUARDIAN" class="marking-text">
                    {{ 'DOCUMENTS_SHARED_WITH_GUARDIAN_MARK' | fromTextKey }}
                  </div>
                  <div v-if="item.documentType == documentTypes.EXTERNAL" class="filetype">
                    {{ 'DOCUMENTS_SHARED_OVERVIEW_TABLE_IMPORTED_FILE' | fromTextKey }}
                  </div>
                </div>
                <div v-if="getDocumentDescription(item)" class="item">
                  <strong>{{ 'DOCUMENTS_SHARED_OVERVIEW_TABLE_DESCRIPTION' | fromTextKey }}:</strong>
                  {{ getDocumentDescription(item) }}
                </div>
                <div class="item">
                  <strong>{{ actionTitle }}:</strong>
                  <nobr> {{ getDocumentsTableDate(item) }} </nobr>
                  <nobr> {{ getDocumentsTableTime(item) }}</nobr>
                </div>
                <div class="item">
                  <strong>{{ 'DOCUMENTS_SHARED_OVERVIEW_TABLE_ASSOCIATION' | fromTextKey }}:</strong>
                  <span v-for="(association, index) in item.associatedInstitutionProfiles" :key="index">
                    <template v-if="index > 0">, </template>{{ association | displayProfileNameWithMetadata }}
                  </span>
                </div>
              </div>
            </div>
            <div
              v-if="parent != parentTypes.EDITOR && profile.role === portalRoles.EMPLOYEE"
              class="table-cell actions"
            >
              <template v-if="includesDeleted">
                <AulaButton
                  :title="'RESTORE' | fromTextKey"
                  :aria-label="'RESTORE' | fromTextKey"
                  class="restore-btn"
                  variant="link"
                  @click.stop="onRestoreClicked(item)"
                >
                  <Icon :name="iconClassEnum.RESTORE" class="restore-icon" />
                  <p class="restore-text">{{ 'RESTORE' | fromTextKey }}</p>
                </AulaButton>
              </template>
              <template v-else>
                <b-form-checkbox
                  v-if="hasPermission(permissionEnum.HANDLE_SECURE_FILES)"
                  v-model="status"
                  class="collapsed-only choose-document"
                  :value="item.id"
                  @click.native.stop="checkClick"
                >
                  <span class="sr-only">{{ 'ARIA_LABEL_CHOOSE_DOCUMENT' | fromTextKey }}</span>
                </b-form-checkbox>
                <b-dropdown
                  :id="'documents-functionalities' + item.id"
                  class="dropdown-select"
                  right
                  aria-hidden="true"
                  @click.stop
                >
                  <b-dropdown-item v-if="item.isLocked" class="is-disabled" @click.stop>
                    {{ 'DOCUMENTS_SHARED_DOCUMENT_IS_LOCKED' | fromTextKey }}
                  </b-dropdown-item>
                  <b-dropdown-item
                    v-if="item.canEditLockedStatus && !item.isLocked"
                    @click.stop="toggleLockDocument(item)"
                  >
                    {{ 'DOCUMENTS_SHARED_LOCK_DOCUMENT' | fromTextKey }}
                  </b-dropdown-item>
                  <div
                    :id="`unlock-${item.id}}`"
                    :class="getCanEditLockStatus(item) ? 'disabled-drop-down' : ''"
                    @click.stop
                  >
                    <b-dropdown-item
                      v-if="getCanUnlock(item)"
                      :disabled="getCanEditLockStatus(item)"
                      @click.stop="toggleLockDocument(item, false)"
                    >
                      {{ 'DOCUMENTS_SHARED_UNLOCK_DOCUMENT' | fromTextKey }}
                    </b-dropdown-item>
                  </div>
                  <b-tooltip
                    v-if="getCanEditLockStatus(item)"
                    :target="`unlock-${item.id}}`"
                    triggers="hover"
                    placement="top"
                  >
                    <div class="document-export-status">
                      {{ getDisabledUnlockOptionTooltip(item) }}
                    </div>
                  </b-tooltip>
                  <template slot="button-content">
                    <span class="sr-only" :aria-label="'ARIA_LABEL_DOCUMENT_FUNCTIONALITIES' | fromTextKey" />
                    <span class="icon-Aula_elipses" />
                  </template>
                  <template v-if="item.canEdit">
                    <b-dropdown-item @click.stop="showModalSharedWith(item.id, item.documentType)">
                      {{ 'DOCUMENTS_IMPORT_FILE_LABEL_DOCUMENT_SHARED_WITH' | fromTextKey }}
                    </b-dropdown-item>
                  </template>
                  <b-dropdown-item @click.stop="seeHistory(item)">
                    {{ 'DOCUMENTS_SHARED_OVERVIEW_MENU_REVISION' | fromTextKey }}
                  </b-dropdown-item>
                  <b-dropdown-item @click.stop="downloadDocument(item)">
                    {{
                      item.documentType == documentTypes.RICHDOCUMENT ||
                      item.documentType == documentTypes.INTERNAL ||
                      item.documentType == documentTypes.NOTE
                        ? 'DOCUMENTS_SHARED_OVERVIEW_MENU_DOWNLOAD_INTERNAL'
                        : 'DOCUMENTS_SHARED_OVERVIEW_MENU_DOWNLOAD' | fromTextKey
                    }}
                  </b-dropdown-item>
                  <b-dropdown-item
                    v-if="
                      item.documentType !== documentTypes.EXTERNAL &&
                      hasPermissionOnInstitution(permissionEnum.EXPORT_SECURE_FILES, item.institutionCode)
                    "
                    @click.stop="showModalExportPDF(item)"
                  >
                    {{ 'DOCUMENTS_SHARED_OVERVIEW_MENU_EXPORT_PDF' | fromTextKey }}
                  </b-dropdown-item>
                  <b-dropdown-item @click.stop="copyLink(item.id, item.documentType)">
                    {{ 'DOCUMENTS_SHARED_OVERVIEW_MENU_COPY' | fromTextKey }}
                  </b-dropdown-item>
                  <div
                    :id="`esdh-export-option-${item.id}`"
                    :class="getIsDisabledExportOption(item) ? 'disabled-drop-down' : ''"
                    @click.stop
                  >
                    <b-dropdown-item
                      v-if="getCanExportToESDH(item)"
                      :disabled="getIsDisabledExportOption(item)"
                      @click.stop="handleEsdhExportClicked(item)"
                    >
                      {{ $t('document.journalise_to_esdh_system') }}
                    </b-dropdown-item>
                  </div>
                  <b-tooltip
                    v-if="getIsDisabledExportOption(item)"
                    :target="`esdh-export-option-${item.id}`"
                    triggers="hover"
                    placement="top"
                  >
                    <div class="document-export-status">
                      {{ getEsdhExportOptionTooltipLabel(item) }}
                    </div>
                  </b-tooltip>
                  <div
                    :id="`delete-option-${item.id}`"
                    :class="getCanDeleteDocument(item) ? 'disabled-drop-down' : ''"
                    @click.stop
                  >
                    <b-dropdown-item
                      v-if="
                        hasPermissionOnInstitution(permissionEnum.SECURE_DOCUMENTS_ACCESS_ALL, item.institutionCode)
                      "
                      :disabled="getCanDeleteDocument(item)"
                      @click.stop="openDeleteDocumentConfirmModal(item)"
                    >
                      {{ 'DOCUMENTS_SHARED_DELETE_MENU' | fromTextKey }}
                    </b-dropdown-item>
                  </div>
                  <b-tooltip
                    v-if="getCanDeleteDocument(item)"
                    :target="`delete-option-${item.id}`"
                    triggers="hover"
                    placement="top"
                  >
                    {{ $t('document.file_is_being_journalized') }}
                  </b-tooltip>
                </b-dropdown>
                <IconContainer
                  v-if="getJournalingStatusIcon(item)"
                  :id="`esdh-export-status-${item.id}`"
                  class="document-icon-container"
                  :data-warning="item.journalingStatus === esdhJournalingStatus.FAILED"
                  @click.stop
                >
                  <Icon :name="getJournalingStatusIcon(item)" />
                </IconContainer>
                <b-tooltip :target="`esdh-export-status-${item.id}`" triggers="hover" placement="top">
                  <div class="document-export-status">
                    {{ getJournalingStatus(item) }}
                  </div>
                </b-tooltip>
                <IconContainer v-if="item.isLocked" class="document-icon-container">
                  <Icon :name="iconClassEnum.LOCK" />
                </IconContainer>
                <AulaButton
                  v-else-if="item.canEdit"
                  variant="link"
                  class="document-icon-container"
                  :aria-label="'ARIA_LABEL_EDIT' | fromTextKey"
                  @click.stop="editElement(item.id, item.documentType)"
                  @keydown.enter.stop="editElement(item.id, item.documentType)"
                >
                  <IconContainer class="document-icon-container">
                    <Icon :name="iconClassEnum.EDIT_PEN" />
                  </IconContainer>
                </AulaButton>
                <IconContainer v-if="item.hasMedia" class="document-icon-container">
                  <Icon :name="iconClassEnum.ATTACH_DOC" />
                </IconContainer>
              </template>
            </div>
          </div>
        </div>
        <aula-spinner v-if="isLoading" />
        <ObservedTarget v-if="documents.length > 0" @onIntersecting="onBottomReached" />
      </ObservingContainer>
      <div v-if="documentItems.documents && documentItems.documents.length == 0" class="no-documents">
        {{
          (profile.role === portalRoles.GUARDIAN
            ? 'DOCUMENTS_SECURE_NO_DOCUMENTS_GUARDIAN'
            : 'DOCUMENTS_SECURE_NO_DOCUMENTS') | fromTextKey
        }}
      </div>
    </div>
    <aula-modal
      ref="documentModal"
      :hide-footer="true"
      :is-scroll-top="false"
      :header-text-from-textkey="false"
      :header-text="liveDocument.title"
      @hidden="$router.push({ name: 'documentsSecureList' })"
    >
      <template v-if="liveDocument.creator != null">
        {{ 'DOCUMENTS_SHARED_OVERVIEW_TABLE_CREATED' | fromTextKey }}: {{ liveDocument.creator.name }}<br />
      </template>
      <template v-if="liveDocument.associatedGroups != null && liveDocument.associatedGroups.length > 0">
        {{ 'DOCUMENTS_SHARED_OVERVIEW_TABLE_GROUP' | fromTextKey }}:
        {{ liveDocument.associatedGroups.map(group => group.name).join(', ') }}<br />
      </template>
      <template
        v-if="
          liveDocument.associatedInstitutionProfiles != null && liveDocument.associatedInstitutionProfiles.length > 0
        "
      >
        {{ 'DOCUMENTS_SHARED_OVERVIEW_TABLE_ASSOCIATION' | fromTextKey }}:
        {{ liveDocument.associatedInstitutionProfiles.map(group => group.name).join(', ') }}<br />
      </template>
      {{ 'DOCUMENTS_SHARED_OVERVIEW_TABLE_CATEGORY' | fromTextKey }}: {{ liveDocument.category }}<br /><br />
      <div v-if="liveDocument.content != null" class="mb-3" v-html="liveDocument.content.html" />
      <attachment-thumbnails :attachments="liveDocument.attachments" :parent="parentTypes.DOCUMENTS" />
      <attachment-list :attachments="liveDocument.attachments" :show-media="true" />
    </aula-modal>
    <aula-modal
      ref="warningDownloadSensitiveFile"
      ok-text="YES"
      @cancelClicked="$refs.warningDownloadSensitiveFile.hide()"
      @okClicked="downloadSensitiveFile()"
    >
      {{ 'DOCUMENTS_ALERT_DOWNLOAD_SENSITIVE_FILE_1' | fromTextKey }}<br />
      {{ 'DOCUMENTS_ALERT_DOWNLOAD_SENSITIVE_FILE_2' | fromTextKey }}
    </aula-modal>
    <aula-modal
      ref="warningLockDocument"
      ok-text="YES"
      @okClicked="doLockDocument()"
      @cancelClicked="resetLockDocument()"
    >
      {{ lockDocumentWarning }} <br />
      {{ 'DOCUMENTS_ALERT_LOCK_DOCUMENT_2' | fromTextKey }}
    </aula-modal>
    <aula-modal
      ref="warningUnlockBeingExportedDocument"
      :show-cancel="false"
      @okClicked="$refs.warningUnlockBeingExportedDocument.hide()"
    >
      {{ $t('document.unlock_being_exported_document_warning_message') }}
    </aula-modal>
    <aula-modal
      ref="warningShareWithDifferentInstitution"
      :show-cancel="false"
      :is-scroll-top="false"
      ok-text="YES"
      @okClicked="$refs.warningShareWithDifferentInstitution.hide()"
    >
      {{ 'DOCUMENTS_ALERT_SHARE_WITH_DIFFERENT_INSTITUTION' | fromTextKey }}
    </aula-modal>
    <toastr ref="copyLinkToastr" :is-scroll-top="false" variant="success" :show-icon="true" icon="icon-Aula_check">
      {{ 'DOCUMENTS_TOASTR_COPY_LINK' | fromTextKey }}
    </toastr>
    <aula-modal
      ref="modalSharedWith"
      :is-scroll-top="false"
      class="modalSharedWith"
      header-text="DOCUMENTS_SHARED_OVERVIEW_MENU_SHARE"
      ok-text="DOCUMENTS_SHARE_FOLDER_MODAL_SAVE"
      @cancelClicked="$refs.modalSharedWith.hide()"
      @okClicked="sharedUsersAndGroupsStore(false)"
    >
      <div class="container-scroll">
        <b-form-group :label="'DOCUMENTS_SHARE_FOLDER_MODAL_USERPICKER' | fromTextKey" label-for="input1">
          <aula-search-recipients
            ref="modalSharedWithrecipients"
            :select-all="false"
            url="findProfilesAndGroupsToShareDocument"
            :show-search-icon="true"
            :show-checkbox="false"
            :enable-relatives-for-students="false"
            :scope-employees-to-institution="false"
            :from-module="moduleTypes.SECURE_DOCUMENT"
            :reset-input="resetSharedWithInput"
            :invite-members-when-selecting-group-portal-role="true"
            :disable-portal-roles-members-when-expanding-group="[portalRoles.CHILD, portalRoles.GUARDIAN]"
            :hide-portal-roles-when-expanding-group="[portalRoles.CHILD, portalRoles.GUARDIAN]"
            :portal-roles="getSearchScope()"
            :include-self="true"
            :existing-participants="appendSharedUsersAndGroupsSet"
            :extra-a-p-i-params="{
              regardingChildren: workingDocument.regardingInstitutionProfileIds,
            }"
            @emitSelected="appendSharedUsersAndGroups"
          />
        </b-form-group>
        <b-container>
          <b-row v-if="currentShared.length > 0">
            <b-col class="col col-7" />
            <b-col cols="5" class="col text-center text-nowrap">
              {{ 'DOCUMENTS_SHARE_FOLDER_MODAL_CAN_EDIT' | fromTextKey }}
            </b-col>
          </b-row>
          <b-row v-for="(sharedItem, index) in currentShared" :key="index" class="mt-3">
            <b-col class="text">
              {{ sharedItem.displayName || sharedItem.groupName }}
            </b-col>
            <b-col cols="2" class="check">
              <b-form-checkbox
                v-model="currentShared[index].canEdit"
                :disabled="sharedItem.role === portalRoles.GUARDIAN"
                :checked="sharedItem.canEdit"
              >
                <span class="sr-only">{{ 'ARIA_LABEL_CHOOSE_DOCUMENT' | fromTextKey }}</span>
              </b-form-checkbox>
            </b-col>
            <b-col cols="1" class="delete">
              <i
                class="icon-Aula_bin"
                tabindex="0"
                role="button"
                :aria-label="'ARIA_LABEL_DELETE' | fromTextKey"
                @click="currentShared.splice(index, 1)"
                @keydown.enter="currentShared.splice(index, 1)"
              />
            </b-col>
          </b-row>
        </b-container>
      </div>
    </aula-modal>
    <aula-modal
      ref="modalSharedWithGroups"
      :is-scroll-top="false"
      class="modalSharedWith"
      header-text="DOCUMENTS_SHARED_OVERVIEW_MENU_SHARE"
      ok-text="DOCUMENTS_SHARE_FOLDER_MODAL_SAVE"
      @cancelClicked="$refs.modalSharedWithGroups.hide()"
      @okClicked="sharedUsersAndGroupsStore(true)"
    >
      <div class="container-scroll">
        <b-form-group :label="'DOCUMENTS_SHARE_FOLDER_MODAL_USERPICKER' | fromTextKey" label-for="input1">
          <aula-search-recipients
            :select-all="false"
            url="findProfilesAndGroupsToShareDocument"
            :enable-relatives-for-students="false"
            :scope-employees-to-institution="false"
            :show-search-icon="true"
            :reset-input="resetSharedWithInput"
            :from-module="moduleTypes.SECURE_DOCUMENT"
            :invite-members-when-selecting-group-portal-role="true"
            :disable-portal-roles-members-when-expanding-group="[portalRoles.CHILD, portalRoles.GUARDIAN]"
            :hide-portal-roles-when-expanding-group="[portalRoles.CHILD, portalRoles.GUARDIAN]"
            :portal-roles="getSearchScope()"
            :include-self="true"
            :existing-participants="appendSharedUsersAndGroupsSet"
            :extra-a-p-i-params="{ regardingChildren: intersectedChildrenIds }"
            @emitSelected="appendSharedUsersAndGroups"
          />
        </b-form-group>
        <b-container>
          <b-row v-if="currentShared.length > 0">
            <b-col class="col col-7" />
            <b-col cols="5" class="col text-center text-nowrap">
              {{ 'DOCUMENTS_SHARE_FOLDER_MODAL_CAN_EDIT' | fromTextKey }}
            </b-col>
          </b-row>
          <b-row v-for="(sharedItem, index) in currentShared" :key="index">
            <b-col class="text">
              {{ sharedItem.displayName || sharedItem.groupName }}
            </b-col>
            <b-col cols="2" class="check">
              <b-form-checkbox
                v-model="currentShared[index].canEdit"
                :disabled="sharedItem.role === portalRoles.GUARDIAN"
                :checked="sharedItem.canEdit"
              >
                <span class="sr-only">{{ 'ARIA_LABEL_CHOOSE_DOCUMENT' | fromTextKey }}</span>
              </b-form-checkbox>
            </b-col>
            <b-col cols="1" class="delete">
              <i
                class="icon-Aula_bin"
                tabindex="0"
                role="button"
                :aria-label="'ARIA_LABEL_DELETE' | fromTextKey"
                @click="currentShared.splice(index, 1)"
                @keydown.enter="currentShared.splice(index, 1)"
              />
            </b-col>
          </b-row>
        </b-container>
      </div>
    </aula-modal>
    <aula-modal
      ref="modalExportPDF"
      ok-text="DOWNLOAD"
      header-text="DOCUMENTS_SHARED_OVERVIEW_MENU_EXPORT"
      :disable-submit="isDisabledExport"
      :is-scroll-top="false"
      :is-loading="isDisabledExport && !isExportFailed"
      @cancelClicked="$refs.modalExportPDF.hide()"
      @okClicked="openFileUrl()"
    >
      {{ 'DOCUMENTS_ALERT_EXPORT_PDF_1' | fromTextKey }} <br /><br />
      {{ 'DOCUMENTS_ALERT_EXPORT_PDF_2' | fromTextKey }}
      <div class="row mb-1 mt-5 pt-5">
        <div class="col-sm-12 pt-1">
          <b-progress :value="fileProgress" />
        </div>
      </div>
      <b-alert variant="danger" :show="isExportFailed">
        {{ 'API_ERROR_EXPORT_PDF' | fromTextKey }}
      </b-alert>
    </aula-modal>
    <aula-modal
      ref="modalSelectedWarning"
      header-text="DOCUMENTS_SHARED_OVERVIEW_MENU_LOCKED_WARNING"
      :show-cancel="false"
      :is-scroll-top="false"
      @okClicked="$refs.modalSelectedWarning.hide()"
    >
      {{ 'DOCUMENTS_ALERT_LOCKED_DOCUMENT_SELECTED' | fromTextKey }}
      <br /><br />
      <ul>
        <li v-for="(item, index) in selectedLockedDocuments" :key="index">
          {{ item.title }}
        </li>
      </ul>
    </aula-modal>
    <aula-modal
      ref="modalSelectedWithoutExportWarning"
      header-text="DOCUMENTS_SHARED_OVERVIEW_MENU_LOCKED_WARNING"
      :show-cancel="false"
      :is-scroll-top="false"
      @okClicked="$refs.modalSelectedWithoutExportWarning.hide()"
    >
      {{ 'DOCUMENTS_ALERT_NO_EXPORT_RIGHT_DOCUMENT_SELECTED' | fromTextKey }}
      <br /><br />
      <ul>
        <li v-for="(item, index) in selectedDocWithoutExportRight" :key="index">
          {{ item.title }}
        </li>
      </ul>
    </aula-modal>
    <aula-modal
      ref="shareWithOtherMunicipalitiesError"
      :show-cancel="false"
      @okClicked="$refs.shareWithOtherMunicipalitiesError.hide()"
    >
      {{ 'API_ERROR_SHARE_WITH_OTHER_MUNICIPALITIES' | fromTextKey }}
    </aula-modal>
    <aula-modal
      ref="deleteSecureDocumentConfirmModal"
      :ok-text="'BUTTON_YES'"
      @okClicked="deleteDocument"
      @cancelClicked="$refs.deleteSecureDocumentConfirmModal.hide()"
    >
      {{ 'DELETE_DOCUMENT_CONFIRM' | fromTextKey }} '{{ selectedDocument.title }}'. <br />
      {{ 'DELETE_DOCUMENT_CONFIRM_2' | fromTextKey }} <br />
      {{ 'DELETE_DOCUMENT_CONFIRM_3' | fromTextKey }}
    </aula-modal>
    <AulaModal
      ref="restoreWarningModal"
      :ok-text="'BUTTON_YES'"
      @okClicked="confirmRestoringDeletedSecureDocument"
      @cancelClicked="cancelRestoring"
    >
      <p v-if="selectedDocument">
        {{ 'DELETED_SECURE_DOCUMENT_RESTORE_WARNING_1' | fromTextKey }} <b>{{ selectedDocument.title }}</b
        >.<br />
        {{ 'DELETED_SECURE_DOCUMENT_RESTORE_WARNING_2' | fromTextKey }}
      </p>
    </AulaModal>
    <AulaModal
      ref="esdhExportInvalidDocumentsWarningModal"
      :ok-text="'BUTTON_YES'"
      :css-class="'document-warning-modal'"
      @okClicked="confirmRemoveInvalidDocuments"
      @cancelClicked="cancelRemoveInvalidDocuments"
    >
      <p v-if="hasDocumentsWithExceededChildren">
        {{ $t('document.document_esdh_exceeded_regarding_children_warning_1') }}
      </p>
      <p v-else-if="hasDocumentsWithNoChildren">
        {{ $t('document.document_esdh_no_regarding_children_warning_1') }}
      </p>
      <p v-else-if="hasBeingJournalizedDocumentsToExport">
        {{ $t('document.document_esdh_export_being_journalized_again_warning_1') }}
      </p>
      <ul v-if="hasDocumentsWithExceededChildren">
        <li v-for="(item, index) in exceedRegardingChildrenDocuments" :key="`${item.id}-${index}`">
          {{ item.title }}
        </li>
      </ul>
      <ul v-else-if="hasDocumentsWithNoChildren">
        <li v-for="(item, index) in noRegardingChildrenDocuments" :key="`${item.id}-${index}`">
          {{ item.title }}
        </li>
      </ul>
      <ul v-else-if="hasBeingJournalizedDocumentsToExport">
        <li v-for="(item, index) in beingJournalizedDocuments" :key="`${item.id}-${index}`">
          {{ item.title }}
        </li>
      </ul>
      <p>
        <span v-if="hasDocumentsWithExceededChildren">
          {{ $t('document.document_esdh_exceeded_regarding_children_warning_2') }}
        </span>
        <span v-else-if="hasDocumentsWithNoChildren">
          {{ $t('document.document_esdh_no_regarding_children_warning_2') }}
        </span>
        <span v-else-if="hasBeingJournalizedDocumentsToExport">
          {{ $t('document.document_esdh_export_being_journalized_again_warning_2') }}
        </span>
        {{ $t('warning.continue') }}
      </p>
    </AulaModal>
    <AulaModal
      ref="noExportRightWarningModal"
      :ok-text="'BUTTON_YES'"
      :css-class="'document-warning-modal'"
      @okClicked="confirmRemoveDocumentsWithoutExportRight"
      @cancelClicked="cancelRemoveDocumentsWithoutExportRight"
    >
      <p v-if="hasDocumentsWithoutJournalizationRight">
        {{ $t('document.dont_have_permission_to_export_in_given_institution_warning_1') }}
      </p>
      <p v-else-if="hasDocumentsWithoutRecipientConfigured">
        {{ $t('document.no_recipients_configured_in_given_municipality_warning_1') }}
      </p>
      <ul v-if="hasDocumentsWithoutJournalizationRight">
        <li v-for="(item, index) in documentsWithoutJournalizationRight" :key="`${item.id}-${index}`">
          {{ item.title }}
        </li>
      </ul>
      <ul v-else-if="hasDocumentsWithoutRecipientConfigured">
        <li v-for="(item, index) in documentsWithoutRecipientConfigured" :key="`${item.id}-${index}`">
          {{ item.title }}
        </li>
      </ul>
      <p v-if="hasDocumentsWithoutJournalizationRight">
        {{ $t('document.dont_have_permission_to_export_in_given_institution_warning_2') }}
      </p>
      <p v-else-if="hasDocumentsWithoutRecipientConfigured">
        {{ $t('document.no_recipients_configured_in_given_municipality_warning_2') }}
      </p>
    </AulaModal>
    <DocumentEsdhExportDrawer
      ref="esdhExportDrawer"
      :selected-documents="selectedEsdhDocumentsToExport"
      @remove="onRemoveSelectedEsdhExportSecurefile"
      @sent="refresh"
    />
  </div>
</template>

<script>
import Vue from 'vue';
import { types } from '../../store/types/types';
import { mapGetters, mapActions, mapMutations } from 'vuex';
import DocumentSecureToolbar from './DocumentSecureToolbar.vue';
import AulaSearchRecipients from '../../../shared/components/AulaSearchRecipients';
import BadgeNotification from '../../../shared/components/BadgeNotification.vue';
import { permissionEnum } from '../../../shared/enums/permissionEnum.ts';
import { docTypes } from '../../../shared/enums/docTypes';
import { parentTypes } from '../../../shared/enums/parentTypes.ts';
import { moduleTypes } from '../../../shared/enums/moduleTypes';
import { documentCategories } from '../../../shared/enums/documentCategories';
import { documentTypes } from '../../../shared/enums/documentTypes';
import { portalRoles } from '../../../shared/enums/portalRoles';
import AttachmentList from '../../../shared/components/AttachmentList';
import AttachmentThumbnails from '../../../shared/components/AttachmentThumbnails';
import { exportingStatus } from '../../../shared/enums/exportingStatus';
import { sortTypes } from '../../../shared/enums/sortTypes';
import { errorSubCodeTypes } from '../../../shared/enums/errorSubCodeTypes';
import requestCancelTokenMixin from '../../../shared/mixins/requestCancelTokenMixin';
import sortBy from 'lodash/sortBy';
import ObservingContainer from '../../../shared/libs/intersection-observer/components/Container.vue';
import ObservedTarget from '../../../shared/libs/intersection-observer/components/Target.vue';
import AulaButton from '../../../shared/components/AulaButton.vue';
import Icon from '../../../shared/components/Icon.vue';
import { iconClassEnum } from '../../../shared/enums/iconClassEnum';
import truncate from 'lodash/truncate';
import DocumentEsdhExportDrawer from './DocumentEsdhExportDrawer.vue';
import { esdhJournalingStatus } from '../../../shared/enums/esdhJournalingStatus';
import IconContainer from '../../../shared/components/IconContainer.vue';
import { esdhDataExportAdminService } from '../../../shared/services/api/esdhDataExportAdmin.service';

const MAX_SECURE_NOTE_DESCRIPTION_LENGTH = 300;
const CHILD_LIMIT_FOR_ESDH_EXPORT = 28;

export default {
  mixins: [requestCancelTokenMixin],
  props: {
    parent: { type: String, default: parentTypes.DOCUMENTS },
    includesDeleted: { type: Boolean, default: false },
  },
  data: function () {
    return {
      cleanDropDowns: false,
      isLoading: true,
      permissionEnum: permissionEnum,
      portalRoles: portalRoles,
      categoriesList: documentCategories,
      docTypes: docTypes,
      parentTypes: parentTypes,
      moduleTypes: moduleTypes,
      documentTypes: documentTypes,
      sortTypes,
      paginationStart: 0,
      pageSize: 20,
      sort: this.parent === parentTypes.ADMINISTRATION ? sortTypes.DELETED_AT : sortTypes.UPDATED_AT,
      sortDirection: 'desc',
      sortTexKeyMap: new Map([
        [sortTypes.TITLE, 'DOCUMENTS_SHARED_OVERVIEW_TABLE_TITLE'],
        [sortTypes.UPDATED_AT, 'DOCUMENTS_SHARED_OVERVIEW_TABLE_UPDATEDAT'],
      ]),
      currentShared: [],
      editDrawer: false,
      appendSharedUsersAndGroupsSet: [],
      filterGroup: [],
      selectedFilterGroup: [],
      filterPerson: [],
      filterCategory: [],
      workingDocument: {
        content: { html: '' },
      },
      selectedFilterPerson: null,
      selectedFilterCategory: null,
      status: [],
      errorMissingDoc: false,
      errorSetAssociation: false,
      errorGroupNotInInstitution: false,
      associatedChildren: [],
      currentChoosenGroupName: null,
      errorSetTitle: false,
      mediaToRemove: {},
      singleStatus: null,
      currentChoosenGroup: null,
      downloadFile: null,
      resetSharedWithInput: false,
      sharedWithGroup: {},
      lockDocumentWarning: null,
      changedDocument: null,
      isLocked: null,
      originalProfilePortalRole: null,
      fileProgress: 0,
      archiveDocumentTrackingTimer: null,
      isDisabledExport: true,
      selectedLockedDocuments: [],
      selectedDocWithoutExportRight: [],
      isExportFailed: false,
      intersectedChildrenIds: [],
      selectedDocument: {},
      documentsCancelTokenSource: null,
      selectedEsdhDocumentsToExport: [],
      institutionCodesCanExportToEsdh: [],
    };
  },
  computed: {
    esdhJournalingStatus() {
      return esdhJournalingStatus;
    },
    iconClassEnum() {
      return iconClassEnum;
    },
    ...mapGetters({
      selectedInstitution: types.GET_SELECTED_INSTITUTION,
      isProfileLoaded: types.IS_PROFILE_LOADED,
      activeInstitutions: types.GET_ACTIVE_INSTITUTIONS,
      activeChildren: types.GET_ACTIVE_CHILDREN,
      profile: types.GET_CURRENT_PROFILE,
      documentItems: types.GET_DOCUMENTS,
      liveDocument: types.GET_DOCUMENTS_LIVE,
      hasPermission: types.HAS_PERMISSION,
      hasPermissionOnInstitution: types.HAS_PERMISSION_ON_INSTITUTION,
      notifications: types.GET_NOTIFICATIONS,
      institutions: types.GET_INSTITUTIONS,
      isMobile: types.GET_IS_MOBILE,
      isSteppedUp: types.GET_GLOBAL_STEPPED_UP,
      userGroups: types.GET_USER_GROUPS,
      groupMemberships: types.GET_GROUP_MEMBERSHIPS_LIGHT,
      archiveDocumentTrack: types.GET_ARCHIVE_DOCUMENT_TRACK,
      archiveDocumentProgress: types.GET_ARCHIVE_DOCUMENT_PROGRESS,
    }),
    exceedRegardingChildrenDocuments() {
      return this.selectedEsdhDocumentsToExport.filter(
        doc => doc.associatedInstitutionProfiles.length > CHILD_LIMIT_FOR_ESDH_EXPORT
      );
    },
    noRegardingChildrenDocuments() {
      return this.selectedEsdhDocumentsToExport.filter(doc => doc.associatedInstitutionProfiles.length === 0);
    },
    beingJournalizedDocuments() {
      return this.selectedEsdhDocumentsToExport.filter(
        doc => doc.journalingStatus !== esdhJournalingStatus.NOT_PROCESSED
      );
    },
    documentsWithoutJournalizationRight() {
      return this.selectedEsdhDocumentsToExport.filter(
        doc => !this.hasPermissionOnInstitution(permissionEnum.JOURNALING_TO_ESDH, doc.institutionCode)
      );
    },
    documentsWithoutRecipientConfigured() {
      return this.selectedEsdhDocumentsToExport.filter(
        doc => this.institutionCodesCanExportToEsdh.indexOf(doc.institutionCode) === -1
      );
    },
    hasDocumentsWithExceededChildren() {
      return this.selectedEsdhDocumentsToExport.some(
        doc => doc.associatedInstitutionProfiles.length > CHILD_LIMIT_FOR_ESDH_EXPORT
      );
    },
    hasDocumentsWithNoChildren() {
      return this.selectedEsdhDocumentsToExport.some(doc => doc.associatedInstitutionProfiles.length === 0);
    },
    hasBeingJournalizedDocumentsToExport() {
      return this.selectedEsdhDocumentsToExport.some(
        doc => doc.journalingStatus !== esdhJournalingStatus.NOT_PROCESSED
      );
    },
    hasDocumentsWithoutJournalizationRight() {
      return this.selectedEsdhDocumentsToExport.some(
        doc => !this.hasPermissionOnInstitution(permissionEnum.JOURNALING_TO_ESDH, doc.institutionCode)
      );
    },
    hasDocumentsWithoutRecipientConfigured() {
      return this.selectedEsdhDocumentsToExport.some(
        doc =>
          this.hasPermissionOnInstitution(permissionEnum.JOURNALING_TO_ESDH, doc.institutionCode) &&
          this.institutionCodesCanExportToEsdh.indexOf(doc.institutionCode) === -1
      );
    },
    isValidToEsdhExport() {
      return (
        !this.hasDocumentsWithExceededChildren &&
        !this.hasDocumentsWithNoChildren &&
        !this.hasBeingJournalizedDocumentsToExport &&
        !this.hasDocumentsWithoutJournalizationRight &&
        !this.hasDocumentsWithoutRecipientConfigured
      );
    },
    actionTitle() {
      return this.includesDeleted
        ? this.$options.filters.fromTextKey('DOCUMENTS_SHARED_OVERVIEW_TABLE_DELETED')
        : this.$options.filters.fromTextKey('DOCUMENTS_SHARED_OVERVIEW_TABLE_EDITED');
    },
    observerOptions() {
      return { rootMargin: '50%' };
    },
    documentsTableTimestampHeader() {
      return this.includesDeleted
        ? this.$options.filters.fromTextKey('DELETED_SECURE_DOCUMENTS_TABLE_DELETED_AT')
        : this.$options.filters.fromTextKey('DOCUMENTS_SHARED_OVERVIEW_TABLE_EDITED');
    },
    documents() {
      return this.documentItems?.documents || [];
    },
    documentPayload() {
      const index = this.paginationStart * this.pageSize;
      const limit = this.pageSize;
      const sortings = [
        {
          order: this.sortDirection,
          field: this.sort,
        },
      ];
      let filterInstitutionProfileIds = [];
      let filterRegardingGroupIds = [];
      let filterRegardingStudentIds = [];
      const filterDocumentCategories = [];

      if (this.activeChildren?.length > 0) {
        filterRegardingStudentIds = filterRegardingStudentIds.concat(this.activeChildren);
      }
      if (this.selectedFilterGroup) {
        filterRegardingGroupIds = this.selectedFilterGroup;
      }
      if (this.selectedFilterPerson) {
        filterRegardingStudentIds = [];
        filterRegardingStudentIds.push(this.selectedFilterPerson);
      }
      if (this.selectedFilterCategory) {
        filterDocumentCategories.push(this.selectedFilterCategory);
      }
      const activeInstitutions = this.activeInstitutions ?? [this.selectedInstitution.institutionCode];
      if (activeInstitutions.length > 0) {
        const result = this.institutions.reduce((result, inst) => {
          if (activeInstitutions.indexOf(inst.institutionCode) != -1) {
            result.push(inst.institutionProfileId);
          }
          return result;
        }, []);
        filterInstitutionProfileIds = result;
      }

      let params;
      const documentInfo = {
        index: index,
        limit: limit,
        sortings: sortings,
        filterRegardingGroupIds: filterRegardingGroupIds,
        filterDocumentCategories: filterDocumentCategories,
        filterRegardingStudentIds: filterRegardingStudentIds,
        filterInstitutionProfileIds: filterInstitutionProfileIds,
      };

      if (this.includesDeleted) {
        params = this.getDeletedSecureDocumentParams(documentInfo);
      } else {
        params = this.getSecureDocumentParams(documentInfo);
      }

      return {
        params: params,
        cancelToken: this.documentsCancelTokenSource.token,
      };
    },
    isDocumentFormShown() {
      return [
        'documentsSecureImport',
        'documentsSecureEdit',
        'documentsSecureNew',
        'createDocumentNote',
        'viewDocument',
      ].includes(this.$route.name);
    },
    canShowMultipleFileActionButtons() {
      return this.status.length >= 1 && !this.includesDeleted;
    },
    canShowMultiSelectAction() {
      return this.hasPermission(permissionEnum.HANDLE_SECURE_FILES) && !this.includesDeleted;
    },
    isProfileSwitched() {
      return this.originalProfilePortalRole != this.profile.role;
    },
    getCurrentActiveInstitutions() {
      const activeInstitutionCodes = this.activeInstitutions ?? [this.selectedInstitution.institutionCode];

      return this.institutions.filter(
        institution => activeInstitutionCodes.indexOf(institution.institutionCode) !== -1
      );
    },
  },
  mounted() {
    this.initDocuments();
  },
  methods: {
    ...mapActions({
      restoreDeletedSecureDocument: types.ACTION_RESTORE_DELETED_DOCUMENT_ADMIN,
      loadDeletedDocuments: types.ACTION_GET_DELETED_DOCUMENTS_ADMIN,
      appendDeletedDocuments: types.ACTION_GET_MORE_DELETED_DOCUMENTS_ADMIN,
      loadDocuments: types.ACTION_GET_DOCUMENTS,
      appendDocuments: types.ACTION_GET_MORE_DOCUMENTS,
      editSharings: types.ACTION_EDIT_SHARINGS,
      editSetupDocument: types.ACTION_LOAD_INTERNAL_DOCUMENT,
      editSetupExternal: types.ACTION_LOAD_EXTERNAL_DOCUMENT,
      deleteNotifications: types.DELETE_NOTIFICATIONS,
      lockDocument: types.LOCK_DOCUMENT,
      loadGroupMemberships: types.LOAD_GROUP_MEMBERSHIPS_LIGHT,
      createArchiveSecureDocuments: types.ACTION_CREATE_ARCHIVE_SECURE_DOCUMENTS,
      trackArchiveSecureDocuments: types.ACTION_TRACK_ARCHIVE_SECURE_DOCUMENTS,
      deleteSecureDocument: types.ACTION_DELETE_DOCUMENT,
    }),
    ...mapMutations({
      setErrorText: types.MUTATE_ERROR_TEXT,
      updateDocumentSharedWith: types.MUTATE_UPDATE_DOCUMENT_SHARED_WITH,
      setStepUpNotification: types.MUTATE_SET_NOTIFICATION_STEP_UP,
      setArchiveDocumentTrack: types.MUTATE_ARCHIVE_DOCUMENT_TRACK,
      setArchiveDocumentProgress: types.MUTATE_ARCHIVE_DOCUMENT_PROGRESS,
    }),
    async initDocuments() {
      if (this.isProfileLoaded) {
        if (this.hasPermission(this.permissionEnum.ACCESS_SECURE_FILESHARING)) {
          if (this.isSteppedUp) {
            this.getDocuments();
            await this.getAccessibleInstitutionCodesForEsdhExport();
          } else {
            this.setStepUpNotification({
              showStepUpNotification: true,
              redirectedUrl: window.location.href,
              cancelUrl: '/dokumenter',
            });
          }
        } else {
          this.isLoading = false;
          this.setErrorText('NO_PERMISSION_TO_VIEW_SECURE_DOCUMENT');
          this.$router.push({ name: 'documents' });
        }
      }
    },
    getCanDeleteDocument(document) {
      return document.journalingStatus === esdhJournalingStatus.IN_PROGRESS;
    },
    getCanExportToESDH(document) {
      return document.canExportToEsdh;
    },
    getCanUnlock(document) {
      return (
        this.hasPermissionOnInstitution(permissionEnum.SECURE_DOCUMENTS_ACCESS_ALL, document.institutionCode) &&
        document.isLocked
      );
    },
    getCanEditLockStatus(document) {
      return !document.canEditLockedStatus;
    },
    getDisabledUnlockOptionTooltip(document) {
      if (document.journalingStatus === esdhJournalingStatus.IN_PROGRESS) {
        return this.$t('document.can_not_unlock_file_being_exported');
      }
      return '';
    },
    getIsDisabledExportOption(document) {
      return (
        !this.getHasEsdhConfiguration(document) ||
        document.journalingStatus === esdhJournalingStatus.IN_PROGRESS ||
        document.journalingStatus === esdhJournalingStatus.FAILED ||
        document.journalingStatus === esdhJournalingStatus.COMPLETED
      );
    },
    getEsdhExportOptionTooltipLabel(document) {
      if (!this.getHasEsdhConfiguration(document)) {
        return this.$t('document.no_esdh_system_specified');
      }

      return this.$t('document.can_not_journalize_already_journalized_document');
    },
    getHasEsdhConfiguration(document) {
      return this.institutionCodesCanExportToEsdh.indexOf(document.institutionCode) !== -1;
    },
    getJournalingStatusIcon(document) {
      if (!this.hasPermissionOnInstitution(permissionEnum.JOURNALING_TO_ESDH, document.institutionCode)) {
        return '';
      }

      switch (document.journalingStatus) {
        case esdhJournalingStatus.IN_PROGRESS:
          if (!this.getHasEsdhConfiguration(document)) {
            return '';
          }

          return iconClassEnum.SWITCH;
        case esdhJournalingStatus.COMPLETED:
          return iconClassEnum.DOCUMENT_BOX;
        case esdhJournalingStatus.FAILED:
          return iconClassEnum.WARNING;
        default:
          return '';
      }
    },
    getJournalingStatus(document) {
      switch (document.journalingStatus) {
        case esdhJournalingStatus.IN_PROGRESS:
          return this.$t('document.journalization_in_progress');
        case esdhJournalingStatus.COMPLETED:
          return this.$t('document.journalization_completed');
        case esdhJournalingStatus.FAILED:
          return this.$t('document.journalization_failed');
        default:
          return '';
      }
    },
    handleEsdhExportClicked(item) {
      this.selectedEsdhDocumentsToExport = [item];

      if (!this.isValidToEsdhExport) {
        this.handleEsdhWarningModals();
        return;
      }

      this.$refs.esdhExportDrawer.show();
    },
    handleMultipleEsdhExportClicked() {
      this.selectedEsdhDocumentsToExport = this.documentItems.documents.filter(
        document => this.status.indexOf(document.id) !== -1
      );

      if (!this.isValidToEsdhExport) {
        this.handleEsdhWarningModals();
        return;
      }

      this.$refs.esdhExportDrawer.show();
    },
    handleEsdhWarningModals() {
      if (
        this.hasDocumentsWithExceededChildren ||
        this.hasDocumentsWithNoChildren ||
        this.hasBeingJournalizedDocumentsToExport
      ) {
        this.$refs.esdhExportInvalidDocumentsWarningModal.show();
        return;
      }
      if (this.hasDocumentsWithoutJournalizationRight || this.hasDocumentsWithoutRecipientConfigured) {
        this.$refs.noExportRightWarningModal.show();
      }
    },
    onRemoveSelectedEsdhExportSecurefile(documentId) {
      const removeIndex = this.selectedEsdhDocumentsToExport.findIndex(document => document.id === documentId);
      this.selectedEsdhDocumentsToExport.splice(removeIndex, 1);

      if (this.selectedEsdhDocumentsToExport.length === 0) {
        this.$refs.esdhExportDrawer.hide();
      }
    },
    getDocumentDescription(doc) {
      switch (doc.documentType) {
        case documentTypes.NOTE:
          return truncate(doc.description ?? '', { length: MAX_SECURE_NOTE_DESCRIPTION_LENGTH });
        case documentTypes.RICHDOCUMENT:
          return doc.templateTitle ?? '';
        default:
          return '';
      }
    },
    getDeletedSecureDocumentParams(documentInfo) {
      return {
        index: documentInfo.index,
        limit: documentInfo.limit,
        sortings: documentInfo.sortings,
        filterRegardingGroupIds: documentInfo.filterRegardingGroupIds,
        filterDocumentCategories: documentInfo.filterDocumentCategories,
        filterRegardingStudentIds: documentInfo.filterRegardingStudentIds,
        selectedInstitutionCode: this.selectedInstitution.institutionCode,
      };
    },
    getSecureDocumentParams(documentInfo) {
      return {
        index: documentInfo.index,
        limit: documentInfo.limit,
        sortings: documentInfo.sortings,
        filterRegardingGroupIds: documentInfo.filterRegardingGroupIds,
        filterDocumentCategories: documentInfo.filterDocumentCategories,
        filterRegardingStudentIds: documentInfo.filterRegardingStudentIds,
        filterInstitutionProfileIds: documentInfo.filterInstitutionProfileIds,
      };
    },
    getDocumentsTableDate(item) {
      return this.includesDeleted
        ? `${this.$options.filters.shortDate(item.deletedAt, true)}`
        : `${this.$options.filters.shortDate(item.updatedAt, true)}`;
    },
    getDocumentsTableTime(item) {
      return this.includesDeleted
        ? `kl. ${this.$options.filters.time(item.deletedAt)}`
        : `kl. ${this.$options.filters.time(item.updatedAt)}`;
    },
    loadSecureDocuments(payload) {
      if (this.includesDeleted) {
        return this.loadDeletedDocuments(payload);
      }
      return this.loadDocuments(payload);
    },
    loadMoreSecureDocuments(payload) {
      if (this.includesDeleted) {
        return this.appendDeletedDocuments(payload);
      }
      return this.appendDocuments(payload);
    },
    onRestoreClicked(secureDocument) {
      this.selectedDocument = secureDocument;
      this.$refs.restoreWarningModal.show();
    },
    confirmRestoringDeletedSecureDocument() {
      this.restoreDeletedSecureDocument({ documentId: this.selectedDocument.id }).then(() => {
        this.refresh();
        this.$refs.restoreWarningModal.hide();
      });
    },
    cancelRestoring() {
      this.$refs.restoreWarningModal.hide();
    },
    confirmRemoveInvalidDocuments() {
      if (this.hasDocumentsWithExceededChildren) {
        this.confirmRemoveExceedRegardingChildrenDocuments();
        return;
      }
      if (this.hasDocumentsWithNoChildren) {
        this.confirmRemoveNoRegardingChildrenDocuments();
        return;
      }
      if (this.hasBeingJournalizedDocumentsToExport) {
        this.confirmRemoveBeingJournalizedDocuments();
      }
    },
    confirmRemoveExceedRegardingChildrenDocuments() {
      this.selectedEsdhDocumentsToExport = this.selectedEsdhDocumentsToExport.filter(
        document => document.associatedInstitutionProfiles.length <= CHILD_LIMIT_FOR_ESDH_EXPORT
      );
      this.handleConfirmInvalidDocumentsWarningModal();
    },
    confirmRemoveNoRegardingChildrenDocuments() {
      this.selectedEsdhDocumentsToExport = this.selectedEsdhDocumentsToExport.filter(
        document => document.associatedInstitutionProfiles.length > 0
      );
      this.handleConfirmInvalidDocumentsWarningModal();
    },
    confirmRemoveBeingJournalizedDocuments() {
      this.selectedEsdhDocumentsToExport = this.selectedEsdhDocumentsToExport.filter(
        doc => doc.journalingStatus === esdhJournalingStatus.NOT_PROCESSED
      );
      this.handleConfirmInvalidDocumentsWarningModal();
    },
    handleConfirmInvalidDocumentsWarningModal() {
      if (this.selectedEsdhDocumentsToExport.length === 0) {
        this.$refs.esdhExportInvalidDocumentsWarningModal.hide();
        return;
      }

      this.$refs.esdhExportInvalidDocumentsWarningModal.hide();

      if (!this.isValidToEsdhExport) {
        this.handleEsdhWarningModals();
        return;
      }

      this.$refs.esdhExportDrawer.show();
    },
    cancelRemoveInvalidDocuments() {
      this.$refs.esdhExportInvalidDocumentsWarningModal.hide();
    },
    confirmRemoveDocumentsWithoutExportRight() {
      if (this.hasDocumentsWithoutJournalizationRight) {
        this.confirmRemoveDocumentsWithoutJournalizationRight();
        return;
      }
      if (this.hasDocumentsWithoutRecipientConfigured) {
        this.confirmRemoveDocumentsWithoutRecipientConfigured();
      }
    },
    cancelRemoveDocumentsWithoutExportRight() {
      this.$refs.noExportRightWarningModal.hide();
    },
    confirmRemoveDocumentsWithoutJournalizationRight() {
      this.selectedEsdhDocumentsToExport = this.selectedEsdhDocumentsToExport.filter(document =>
        this.hasPermissionOnInstitution(permissionEnum.JOURNALING_TO_ESDH, document.institutionCode)
      );
      this.handleConfirmNoExportRightWarningModal();
    },
    confirmRemoveDocumentsWithoutRecipientConfigured() {
      this.selectedEsdhDocumentsToExport = this.selectedEsdhDocumentsToExport.filter(
        document =>
          this.hasPermissionOnInstitution(permissionEnum.JOURNALING_TO_ESDH, document.institutionCode) &&
          this.institutionCodesCanExportToEsdh.indexOf(document.institutionCode) !== -1
      );
      this.handleConfirmNoExportRightWarningModal();
    },
    handleConfirmNoExportRightWarningModal() {
      if (this.selectedEsdhDocumentsToExport.length === 0) {
        this.$refs.noExportRightWarningModal.hide();
        return;
      }

      this.$refs.noExportRightWarningModal.hide();

      if (!this.isValidToEsdhExport) {
        this.handleEsdhWarningModals();
        return;
      }

      this.$refs.esdhExportDrawer.show();
    },
    navigateToSecureList() {
      this.$router.push({ name: 'documentsSecureList' });
    },
    navigateToEditForm({ documentType, id }) {
      this.$router.push({
        name: 'documentsSecureEdit',
        params: { documentType, documentId: id },
      });
    },
    onBottomReached() {
      if (this.isLoading || !this.documentItems.moreDocumentsExists) {
        return;
      }
      this.getMoreDocuments();
    },
    getSearchScope() {
      if (this.profile.role === this.portalRoles.EMPLOYEE) {
        return [this.portalRoles.EMPLOYEE, this.portalRoles.GUARDIAN];
      } else {
        return [this.portalRoles.EMPLOYEE];
      }
    },
    toggleLockDocument(document, isLocked = true) {
      this.changedDocument = document;
      this.isLocked = isLocked;

      if (isLocked) {
        this.lockDocumentWarning = Vue.filter('fromTextKey')('DOCUMENTS_ALERT_LOCK_DOCUMENT_1', {
          documentName: document.title,
        });
        this.$refs.warningLockDocument.show();
        return;
      } else if (document.journalingStatus === esdhJournalingStatus.IN_PROGRESS) {
        this.$refs.warningUnlockBeingExportedDocument.show();
        return;
      }

      this.doLockDocument();
    },
    resetLockDocument() {
      this.changedDocument = null;
      this.isLocked = null;
      this.lockDocumentWarning = null;
      this.$refs.warningLockDocument.hide();
    },
    doLockDocument() {
      this.lockDocument({
        documentId: this.changedDocument.id,
        isLocked: this.isLocked,
      }).then(() => {
        if (
          this.changedDocument.documentType === documentTypes.RICHDOCUMENT ||
          this.changedDocument.documentType === documentTypes.NOTE
        ) {
          this.editSetupDocument({
            id: this.changedDocument.id,
            isUpdateInList: true,
          });
        } else if (this.changedDocument.documentType === documentTypes.EXTERNAL) {
          this.editSetupExternal({
            id: this.changedDocument.id,
            isUpdateInList: true,
          });
        }
        this.resetLockDocument();
      });
    },
    copyLink(id, type) {
      const url = 'https://' + location.host + '/#/dokumenter/sikre/' + type + '/' + id;
      this.copyStringToClipboard(url);
    },
    copyStringToClipboard(str) {
      const el = document.createElement('textarea');
      el.value = str;
      el.setAttribute('readonly', '');
      el.style = { position: 'absolute', left: '-9999px' };
      document.body.appendChild(el);
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);
      this.$refs.copyLinkToastr.show();
    },
    showModalExportPDF(item) {
      this.selectedDocWithoutExportRight = this.documentItems.documents.filter(
        item =>
          this.status.indexOf(item.id) !== -1 &&
          !this.hasPermissionOnInstitution(permissionEnum.EXPORT_SECURE_FILES, item.institutionCode)
      );
      if (this.selectedDocWithoutExportRight.length > 0) {
        this.$refs.modalSelectedWithoutExportWarning.show();
        return;
      }

      this.fileProgress = 0;
      this.isDisabledExport = true;
      this.isExportFailed = false;
      this.setArchiveDocumentTrack(null);
      this.setArchiveDocumentProgress(null);
      this.$refs.modalExportPDF.show();
      const creatingPayload = {
        secureDocumentIds: item ? [item.id] : this.status,
      };

      this.createArchiveSecureDocuments(creatingPayload).then(() => {
        this.archiveDocumentTrackingTimer = setInterval(() => {
          if (!this.$refs.modalExportPDF.showAulaModal) {
            clearInterval(this.archiveDocumentTrackingTimer);
            this.setArchiveDocumentTrack(null);
            this.setArchiveDocumentProgress(null);
          } else {
            const trackingPayload = {
              requestId: this.archiveDocumentTrack,
            };

            this.trackArchiveSecureDocuments(trackingPayload)
              .then(() => {
                if (this.archiveDocumentProgress) {
                  this.fileProgress = this.archiveDocumentProgress.progress * 100;
                  if (this.archiveDocumentProgress.status === exportingStatus.COMPLETED) {
                    clearInterval(this.archiveDocumentTrackingTimer);
                    this.isDisabledExport = false;
                  }
                }
              })
              .catch(() => {
                clearInterval(this.archiveDocumentTrackingTimer);
                this.isExportFailed = true;
              });
          }
        }, 3000);
      });
    },
    openFileUrl() {
      if (this.archiveDocumentProgress && this.archiveDocumentProgress.status === exportingStatus.COMPLETED) {
        window.open(this.archiveDocumentProgress.fileUrl);
      }
    },
    checkClick() {
      return false;
    },
    updateFilter(group) {
      this.paginationStart = 0;
      this.$nextTick(() => {
        if (group) {
          this.selectedFilterGroup = group.map(g => g.id);
        }
        this.getDocuments();
      });
    },
    setSortOrder(sortItem) {
      this.paginationStart = 0;
      if (this.sort == sortItem) {
        this.sortDirection = this.sortDirection == 'asc' ? 'desc' : 'asc';
      } else {
        this.sort = sortItem;
        if (sortItem === sortTypes.UPDATED_AT) {
          this.sortDirection = 'desc';
        } else {
          this.sortDirection = 'asc';
        }
      }
      this.getDocuments();
    },
    refresh() {
      this.paginationStart = 0;
      this.getDocuments();
    },
    getDocuments() {
      if (this.documentsCancelTokenSource !== null) {
        this.cancelAxiosRequest(this.documentsCancelTokenSource);
        this.resetDocumentsCancelToken();
      }
      if (this.paginationStart == 0) {
        this.status = [];
      }
      this.createDocumentsCancelToken();

      let promise;
      this.isLoading = true;

      if (this.paginationStart === 0) {
        promise = this.loadSecureDocuments(this.documentPayload);
      } else {
        promise = this.loadMoreSecureDocuments(this.documentPayload);
      }

      promise.then(() => {
        this.populateFilters();
        this.isLoading = false;
      });
    },
    populateFilters() {
      const toOption = (value, text) => ({ value, text });
      const allChildrenOption = toOption(null, this.$options.filters.fromTextKey('DOCUMENT_FILTER_BY_CHILD'));
      const allCategoriesOption = toOption(null, this.$options.filters.fromTextKey('DOCUMENT_FILTER_BY_CATEGORY'));

      const filters = this.documentItems.filters ?? [];
      const children = filters.regardingInstitutionProfiles ?? [];
      const categories = filters.documentCategories ?? [];

      const sortedChildren = sortBy(children, ['name']);

      this.filterPerson = [
        allChildrenOption,
        ...sortedChildren.map(item => toOption(item.id, this.$options.filters.displayProfileNameWithMetadata(item))),
      ];

      this.filterCategory = [allCategoriesOption, ...categories.map(item => toOption(item, item))];
    },
    getMoreDocuments() {
      this.paginationStart++;
      this.getDocuments();
    },
    editElement(documentId, documentType) {
      this.$router.push({
        name: 'documentsSecureEdit',
        params: { documentId, documentType },
      });
    },
    downloadSensitiveFile() {
      this.$refs.warningDownloadSensitiveFile.hide();
      this.editSetupExternal({ id: this.downloadFile.documentId }).then(response => {
        if (!response.code) {
          let documentUrl = null;
          if (this.liveDocument.attachment.file != null) {
            documentUrl = this.liveDocument.attachment.file.url;
          } else if (this.liveDocument.attachment.media != null) {
            documentUrl = this.liveDocument.attachment.media.file.url;
          } else if (this.liveDocument.attachment.link != null) {
            documentUrl = this.liveDocument.attachment.link.url;
          }
          if (documentUrl != null) {
            window.open(documentUrl, '_blank');
          }
        } else {
          this.setErrorText('NO_PERMISSION_TO_VIEW_SECURE_SINGLE_DOCUMENT');
        }
      });
      this.downloadFile = null;
    },
    rowClickAction(file) {
      const notification = this.notifications.find(not => not.documentId == file.id);
      if (notification != null) {
        this.deleteNotifications({
          notifications: [
            {
              notificationId: notification.notificationId,
              institutionProfileId: notification.institutionProfileId,
            },
          ],
        });
      }
      this.$router.push({
        name: 'viewDocument',
        params: {
          documentType: file.documentType,
          documentId: file.id,
          includesDeleted: this.includesDeleted,
        },
      });
    },
    downloadDocument(file) {
      if (file.documentType == documentTypes.EXTERNAL) {
        this.downloadFile = {
          documentType: file.documentType,
          documentId: file.id,
        };
        this.$refs.warningDownloadSensitiveFile.show();
      } else {
        this.$router.push({
          name: 'viewDocument',
          params: { documentType: file.documentType, documentId: file.id },
        });
      }
    },
    seeHistory(document) {
      this.$router.push({
        path: '/dokumenter/' + document.documentType + '/' + document.id + '/historie',
      });
    },
    sharedUsersAndGroupsStore(multi) {
      let documentIds = [];
      let resetSharings = true;
      this.intersectedChildrenIds = [];
      if (multi) {
        documentIds = this.status;
        resetSharings = false;
      } else {
        documentIds = [this.singleStatus];
      }
      const sharedInstitutionProfiles = [];
      const sharedGroups = [];
      this.currentShared.forEach(function (entry) {
        if (entry.profileId) {
          sharedInstitutionProfiles.push({
            institutionProfileId: entry.profileId,
            canEdit: entry.canEdit || false,
          });
        } else {
          sharedGroups.push({
            groupId: entry.groupId,
            institutionCode: entry.institutionCode,
            canEdit: entry.canEdit || false,
          });
        }
      });

      const payload = {
        documentIds,
        sharedInstitutionProfiles,
        sharedGroups,
        resetSharings,
      };
      this.editSharings(payload).then(data => {
        if (!data) {
          this.updateDocumentSharedWith({
            currentShared: this.currentShared,
            documentIds: documentIds,
          });
        }
        this.$refs.modalSharedWith.hide();
        this.$refs.modalSharedWithGroups.hide();
        this.status = [];
        this.currentShared = [];
        this.singleStatus = null;
        this.getDocuments();
        if (data?.response?.data?.status?.subCode == errorSubCodeTypes.errorShareWithOtherMunicipalities) {
          this.$refs.shareWithOtherMunicipalitiesError.show();
        }
      });
    },
    showModalSharedWith(objItem, docType) {
      const id = objItem;
      this.singleStatus = id;
      const params = {
        id,
      };

      if (docType == documentTypes.EXTERNAL) {
        this.editSetupExternal(params).then(() => {
          this.currentShared = [];
          this.workingDocument = this.liveDocument;
          this.appendSharedUsersAndGroupsSet = [];
          this.workingDocument.regardingInstitutionProfileIds = this.workingDocument.associatedInstitutionProfiles.map(
            child => child.id
          );
          for (const item of this.workingDocument.sharedWithGroups) {
            this.currentShared.push({
              groupId: item.id,
              displayName: item.name,
              canEdit: item.canEdit,
              role: item.role,
            });
          }
          for (const item of this.workingDocument.sharedWithInstitutionProfiles) {
            this.currentShared.push({
              profileId: item.id,
              displayName: Vue.filter('displayProfileNameWithMetadata')(item),
              canEdit: item.canEdit,
              role: item.role,
            });
          }
          this.$refs.modalSharedWith.show();
        });
      } else {
        this.editSetupDocument(params).then(() => {
          this.currentShared = [];
          this.workingDocument = this.liveDocument;
          this.appendSharedUsersAndGroupsSet = [];
          this.workingDocument.regardingInstitutionProfileIds = this.workingDocument.associatedInstitutionProfiles.map(
            child => child.id
          );
          for (const item of this.workingDocument.sharedWithGroups) {
            this.currentShared.push({
              groupId: item.id,
              displayName: item.name,
              canEdit: item.canEdit,
              role: item.role,
            });
          }
          for (const item of this.workingDocument.sharedWithInstitutionProfiles) {
            this.currentShared.push({
              profileId: item.id,
              displayName: Vue.filter('displayProfileNameWithMetadata')(item),
              canEdit: item.canEdit,
              role: item.role,
            });
          }
          this.$refs.modalSharedWith.show();
        });
      }
    },
    appendSharedUsersAndGroups(users) {
      if (users.length > 0) {
        for (const user of users) {
          if (user.type == docTypes.PROFILE.toLowerCase()) {
            if (this.currentShared.filter(e => e.profileId == user.id).length == 0) {
              this.currentShared.push({
                profileId: user.id,
                canEdit: false,
                displayName: user.label,
                shortName: null,
                role: user.portalRole,
              });
            }
            if (
              !user.fromGroup &&
              !this.institutions.find(institution => institution.institutionCode == user.institutionCode) &&
              !this.institutions.find(institution => institution.municipalityCode == user.municipalityCode)
            ) {
              this.$refs.warningShareWithDifferentInstitution.show();
            }
          }
        }
      }
      this.appendSharedUsersAndGroupsSet = [];
      this.resetSharedWithInput = !this.resetSharedWithInput;
    },
    addSharingGroups() {
      this.selectedLockedDocuments = this.documentItems.documents.filter(
        item => this.status.indexOf(item.id) !== -1 && item.canEdit === false
      );
      this.$nextTick(() => {
        if (this.status.length && !this.selectedLockedDocuments.length) {
          this.currentShared = [];
          this.findIntersectedChildrenIds();
          this.$refs.modalSharedWithGroups.show();
        } else {
          this.$refs.modalSelectedWarning.show();
        }
      });
    },
    findIntersectedChildrenIds() {
      let result = [];

      if (this.documentItems && this.documentItems.documents) {
        const selectedDocuments = this.documentItems.documents.filter(doc => this.status.includes(doc.id));

        if (selectedDocuments && selectedDocuments.length > 0) {
          const childrenIdArrays = selectedDocuments.map(doc =>
            doc.associatedInstitutionProfiles.map(child => child.id)
          );
          result = childrenIdArrays.reduce((arr1, arr2) => arr1.filter(item => arr2.includes(item)));
        }
      }
      this.intersectedChildrenIds = result;
    },
    createDocumentsCancelToken() {
      this.documentsCancelTokenSource = this.getAxiosCancelTokenSource();
    },
    resetDocumentsCancelToken() {
      this.documentsCancelTokenSource = null;
    },
    deleteDocument() {
      this.deleteSecureDocument({ id: this.selectedDocument.id }).then(() => {
        this.refresh();
        this.$refs.deleteSecureDocumentConfirmModal.hide();
      });
    },
    openDeleteDocumentConfirmModal(item) {
      this.$refs.deleteSecureDocumentConfirmModal.show();
      this.selectedDocument = item;
    },
    async getAccessibleInstitutionCodesForEsdhExport() {
      this.institutionCodesCanExportToEsdh = [];
      const activeInstitutionCodes = this.getCurrentActiveInstitutions.map(institution => institution.institutionCode);
      try {
        for (const institutionCode of activeInstitutionCodes) {
          if (!this.hasPermissionOnInstitution(permissionEnum.JOURNALING_TO_ESDH, institutionCode)) {
            continue;
          }

          const recipientNames = await esdhDataExportAdminService.getRecipientNames(institutionCode);

          if (recipientNames.length > 0) {
            this.institutionCodesCanExportToEsdh.push(institutionCode);
          }
        }
      } catch {
        this.setErrorText('API_ERROR_GENERAL');
      }
    },
  },
  watch: {
    activeInstitutions() {
      if (this.hasPermission(this.permissionEnum.ACCESS_SECURE_FILESHARING) && !this.isProfileSwitched) {
        this.refresh();
        this.getAccessibleInstitutionCodesForEsdhExport();
      } else {
        this.$router.push({ name: 'documents' });
      }
    },
    activeChildren() {
      this.refresh();
    },
  },
  created() {
    this.originalProfilePortalRole = this.profile.role;
  },
  components: {
    IconContainer,
    DocumentEsdhExportDrawer,
    Icon,
    AulaButton,
    ObservedTarget,
    ObservingContainer,
    AttachmentList,
    AttachmentThumbnails,
    DocumentSecureToolbar,
    AulaSearchRecipients,
    BadgeNotification,
  },
};
</script>

<style scoped lang="scss">
@import '../../../shared/assets/scss/core/variables.scss';
@import '../../../shared/assets/scss/core/breakpoints.scss';
@import '../../../shared/assets/scss/components/documents/_overview.scss';

.restore-icon {
  --font-size: 1.8rem;
}

.restore-btn {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-left: 0;
  width: 100px;

  .restore-text {
    margin-top: 4px;
    font-weight: 400;
    text-transform: capitalize;
  }
}

.deleted-secure-documents-text-container {
  margin-bottom: 20px;
  .header-text {
    font-size: 32px;
    font-weight: 800;
    line-height: 40px;
    margin-bottom: 10px;
  }

  .description-text {
    font-size: 15px;
    font-weight: 400;
  }
}

.breadcumb-spacer {
  padding: 0 10px;
  display: inline-block;
}
.edit-document {
  width: auto;
  height: 30px;
}

.document-icon-container {
  width: 100%;
  height: 30px;
  background-color: transparent;

  .aula-icon {
    font-size: 20px;
  }

  &[data-warning] {
    color: var(--color-alert);
  }
}

.document-export-status {
  display: flex;
  justify-content: center;
  align-items: center;

  color: var(--color-primary-darker);
  font-size: 14px;
  font-weight: 400;
  min-width: 120px;
}

.table-cell.check {
  width: 40px;
  padding: 0 12px 0 2px !important;
  .custom-checkbox {
    padding: 4px;
    margin-bottom: 0;
    left: 30px;
    top: -3px;
  }
}
.table-cell.actions {
  .custom-checkbox {
    margin-bottom: 20px;
    left: -8px;
  }
}

.upload-link {
  cursor: pointer;
  float: left;
  margin-top: 12px;
}
.submit-row {
  margin-top: 15px;
}
.modalSharedWith {
  .row {
    .col,
    .col-1,
    .col-2,
    .col-4 {
      padding: 10px 10px 5px 10px;
      border-bottom: solid 1px #eee;
    }
    .text {
      padding-top: 13px;
    }
    .check {
      text-align: center;
      label {
        margin-right: 0;
      }
    }
    .delete {
      text-align: right;
      padding-top: 14px;
    }
  }
}

.badge-notification.documents {
  --position: unset;
  margin-inline-end: 10px;
  float: left;

  /deep/ .aula-badge {
    width: 18px;
    height: 18px;
    i:before {
      content: '';
    }
  }
}
.table-cell.collapsed-only /deep/ .badge {
  position: relative;
  top: 9px;
  left: 30px;
}
.drawer-content .aula-spinner {
  width: 80px;
  height: 80px;
  position: absolute;
  right: 40px;
  top: -12px;
}
.show-more {
  padding: 20px 0;
  text-align: center;
  button {
    float: none;
  }
}
.modalSharedWith /deep/ .el-input input {
  background-color: $color-grey-light;
}

.table-controls {
  position: relative;
  display: flex;
}

.sort {
  margin-top: auto;
}

.tooltip {
  div {
    text-align: left !important;
  }
}

.choose-document {
  @include breakpoint-lg-down() {
    left: -10px !important;
  }
}

.filetype {
  color: $color-primary-dark-guardian;
  .theme-employee & {
    color: $color-primary-dark-employee;
  }
}

.mobile-left {
  @include breakpoint-lg-down() {
    width: 100%;
  }
}
/deep/ .table {
  overflow-wrap: anywhere;
  .table-row.header {
    &[data-parent-type='administration'] {
      font-size: 15px;
      font-weight: 700;
    }
  }
  .table-cell {
    .dropdown-select {
      .dropdown-menu {
        max-width: 210px !important;

        .disabled-drop-down .dropdown-item.disabled {
          background-color: transparent;
        }
      }
    }
  }
}
.no-documents {
  width: 100%;
  margin: 20px 0;
  font-weight: bold;
}
.document-title {
  display: flex;
  .file-document {
    width: auto;
    text-transform: unset;
    font-size: 18px;
    font-weight: 400;
    height: unset;
    margin-top: -3px;
    margin-left: 0;
    color: $color-primary-dark-guardian;
    .theme-employee & {
      color: $color-primary-dark-employee;
    }
  }
}

.documents-secure-list-container {
  @include breakpoint-lg-down() {
    padding-bottom: var(--menu-item-height);
  }
  @include breakpoint-sm-down {
    .aula-search-outer {
      margin-bottom: 4px;
    }
  }
}

.marking-text {
  color: $color-alert;
}
.alert-text {
  color: $color-alert;
}
.multiple-file-actions {
  clear: both;
  margin-left: -10px;
  margin-bottom: 10px;
  overflow: auto;

  @include breakpoint-sm-down() {
    .btn {
      margin-bottom: 8px;

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
}
.sort {
  &.asc::before,
  &.desc::before {
    margin-left: 3px !important;
    margin-right: -3px !important;
    color: unset !important;
  }
}

.secure-files-filter-container {
  margin-bottom: 4px;
  .dropdown-filter-container {
    display: flex;
    justify-content: flex-end;
  }
}
</style>
